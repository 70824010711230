import React from 'react'



class DevProcess extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-use dao pt-100 secureof">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Services Provided</span> By Our DAO Development Company</h2>
          <p className='text-center'>Pioneer your decentralized governance with our encompassing  DAO Development services.  Harness the full potential for a community-driven approach with transparency and security at its core.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                DAO Node Development
                  </h3>
                <p className="pharagraph">Our DAO Node Development empowers your business by integrating the best voting mechanisms. This algorithmic mechanism can be used to authenticate the self-sustained way to uplift your platform. Nodes will help you in the smooth operation and voting process of the platform.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                dApp Development  
                </h3>
                <p className="pharagraph">Elevate your platform to the next level with our dApp development services. Launch your mobile /Web applications enhancing the easy trading experience for both the users and businesses for easy and seamless trading. Our technical team engages in your dApp development for agile and optimal performance. </p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Smart contract integration
                </h3>
                <p className="pharagraph">Our team excels in building agile smart contracts tailored to your business needs. Ensure the security and easily automated function of the DAO platform by incorporating secure smart contracts. This offers you immutability thereby allowing you to self-govern the platform leveraging the benefits of decentralization.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                DAO Consultations
                </h3>
                <p className="pharagraph">Being the best decentralized autonomous organization development company and also with our professional experts, we are providing DAO advice, consultations, and services. We help investors and startups who plan to incorporate DAO into their platform which promises a smooth and effortless experience.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                DAO Integration
                </h3>
                <p className="pharagraph">Integrate DAO into your business platform to give your audience an automated governing and easy experience. Our developers can help you incorporate DAO into your already existing platform to enjoy the maximum advancement of blockchain technology and Decentralized Exchanges.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                DAO Token Development
                </h3>
                <p className="pharagraph">Effortlessly tokenize your digital assets with our DAO Token Development solutions. The DAO tokens are integrated with smart contracts and can serve diverse and distinct purposes within the network offering transparency, security, and other advanced functionalities.</p>
              </div>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevProcess