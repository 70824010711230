import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">What Makes Coinsclone</span> The Best  DAO Development Company?</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/dao/what-make-dao.webp"
                alt="best dao development company"
                placeholder='none'
                className='mt-3'
                width={525}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className='pharagraph'>Begin your journey with Coinsclone, a world-class leading DAO development company. With our premier web developers and innovative teammates, you can completely rely on us for your DAO development. From conception to deployment, Coinsclone lights you throughout your journey with thoughtful, futuristic, and high-end DAO development services. We are standing firm with our specialized budget-friendly services that promise the best quality. With our unwavering 24/7 customer support, we’re here to help you in your dream of DAO development.</p>
              <ul className='mb-0 mt-4'>
                <li>Rapid development solutions.</li>
                <li>Team of certified professional experts.</li>
                <li>Bug-free solutions at an affordable rate.</li>
                <li>100% Transparent and budgeted service.</li>
                <li>Security and Community Centric Approach.</li>
                <li>One roof solution for Ideation to Implementation.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose