import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center">DAO Development Company - <span className='bluecolor'>Coinsclone</span></h2>
            <div className="text-center">
              <p className="pharagraph text-center mb-0">Coinsclone creates a secure and transparent autonomous system that regulates and self-governs your platform by leveraging the advancements of blockchain technology and automated smart contracts. The Decentralized Autonomous Organisation is a blockchain-supported system that brings a secure path to communicate on the internet among users. Protect the interests of your business and address the challenges with our cost-effective Decentralized Autonomous Organisation development services. Coinsclone is the finest Decentralized Autonomous Organisation Development company that is expert in offering services at top-notch quality and affordable rates.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis