import React from "react"

class DevService extends React.Component {

  render() {
    return (

      <section className="dapps dao pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center">Coinsclone’s DAO <span className="bluecolor">Development Process</span></h2>
            <p className='text-center'>Revolutionize your business decision-making dynamics and autonomy system with our innovative Decentralized Autonomous Organization development process.</p>
          <div className="row">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Consultation</h3>
                            <p className='pharagraph'>Coinsclone places utmost importance on satisfying customers' needs and business requirements. We ensure that our business solutions align perfectly with your goals. Our experts offer direct consultations with clients to understand their preferences thoroughly. Based on this, we provide suggestions aiming to achieve your business goals.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Deciding Frameworks</h3>
                            <p className='pharagraph'>For every successful business, proper planning is a crucial step. After gathering requirements, we next set a framework, and decide on the tech stacks for your DAO development that align with business needs and help you reach your goals.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Smart Contract Development</h3>
                            <p className='pharagraph'>The next step is to integrate the required smart contracts into your DAO development. With our high-quality web coders, we create codes that govern the DAO’s functionality, ensuring a smooth functioning, voting process, seamless transfers, and other functional features.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Development</h3>
                            <p className='pharagraph'>Depending upon the complexity of the project, we handle the front-end, and back-end development process. Adopting intuitive user interface design and integration of other advanced features are done here. The development process goes through multiple stages to carefully curate your DAO development.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Testing</h3>
                            <p className='pharagraph'>Our team of testers goes through frequent testing of the project. The developed DAO project goes through different stages of testing to ensure that there are no bugs or technical glitches. In case of any bugs or lag we fix them here.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Deployment</h3>
                            <p className='pharagraph'>Following the testing process, the most important stage is deploying the platform efficiently. We offer high support to help during the platform’s initial period of launch. Coinsclone makes sure that the platform is successfully deployed at the demand of the users.</p>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevService
