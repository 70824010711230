import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/dao/Banner';
import Whatis from '../components/dao/Whatis';
import DevService from '../components/dao/DevServices';
import WhyChoose from '../components/dao/WhyChoose';
import FaqSection from '../components/dao/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import TechStack from '../components/dao/TechStack';
import DevProcess from '../components/dao/DevProcess';
import Benificial from '../components/dao/Benificial';





const DoaDev = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/dao-development-company/" />
        <title>DAO Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is the leading DAO development company that offers the best DAO development services from consultation to development at high quality on a minimal budget." />
        <meta name="keywords" content="DAO development company, DAO development services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DAO Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is the leading DAO development company that offers the best DAO development services from consultation to development at high quality on a minimal budget." />
        <meta property="og:url" content="https://www.coinsclone.com/dao-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/dao/dao-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is the leading DAO development company that offers the best DAO development services from consultation to development at high quality on a minimal budget." />
        <meta name="twitter:title" content="DAO Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/dao/dao-development-company.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | DAO Development Company</span>
        </div>
      </div>
      <Whatis />
      <DevProcess />
      <Benificial />
      <DevService />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default DoaDev
