import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>DAO</span> Development Company</h1>
                <p className='pharagraph'>Coinsclone is a leading, well-known DAO Development Company that develops the best decentralized autonomous organizations by integrating smart contract features. We provide prime DAO development services with top-notch functionalities satisfying our client's requirements. 
Join us in transforming your business's regulatory and governing framework with our DAO development services where we blend high tech and innovation.
</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/dao/dao-development-company.webp"
                alt="dao development company"
                className='d-none d-md-block float-end'
                width={601}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection